

import { CF2Component } from 'javascript/lander/runtime'

export default class SelectBoxV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
      this.select = this.element.querySelector('.elSelect');

      this.addSelectEventHandlers(this.select)
      this.update()
    }

    update(){
      if (this.select.value) {
        this.element.classList.add('hasValue')
      } else {
        this.element.classList.remove('hasValue')
      }
    }

    addSelectEventHandlers(select){
      select.addEventListener('focus', () => {
        this.element.querySelector('[data-input-status-type]').innerHTML = ''
        this.element.classList.remove("elInputValid")
        this.element.classList.remove("elInputError")
        this.element.classList.remove("elInputWarning")
        this.element.classList.add('elInputFocused');
        this.update()
      })

      select.addEventListener('blur', () => {
        this.element.classList.remove('elInputFocused');
        this.update()
      })

      select.addEventListener('change', () => {
        this.update()
      })
    }



}

window["SelectBoxV2"] = SelectBoxV2

